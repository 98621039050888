/* SVG spinner icon animation */
.spinner {
  padding-top: 5rem;
  padding-bottom: 5rem;
  animation: rotate 3s linear infinite;

  width: 10rem;
  margin-left: auto;
  margin-right: auto;
  display: block;

  .path {
    stroke: #a1a1a1;
    stroke-linecap: round;
    animation: dash 2.5s ease-in-out infinite;
  }
}

@keyframes rotate {
  100% {
      transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 170;
    stroke-dashoffset: 0;
  }
  60% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -65;
  }
  100% {
    stroke-dasharray: 90, 180;
    stroke-dashoffset: -150;
  }
}
